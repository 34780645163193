







































































































































































































.slide-enter {
  opacity: 0;
}

.slide-enter-active {
  animation: slide-in .5s ease-in forwards;
  transition: opacity .3s;
}

.slide-leave-active {
  animation: slide-out .5s ease-in forwards;
  transition: opacity .3s;
  opacity: 0;
}

@keyframes slide-in {
  from {
    transform: translateY(10px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(10px);
  }
}

